import styled from 'styled-components';

import { Txt2, Txt2Bold } from '@src/theme/common';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }): string => theme.spacing(2)};
  padding: ${({ theme }): string => theme.spacing(1.5)};
  border: 1px solid ${({ theme }): string => theme.palette.graphite[20]};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
`;

export const MessageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }): string => theme.spacing()};
  flex-grow: 1;
`;

export const Title = styled.h3`
  ${Txt2};
`;

export const Text = styled.span`
  ${Txt2Bold};
`;

export const ActionButton = styled.button`
  display: block;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  -moz-tap-highlight-color: transparent;

  &:active {
    opacity: 0.7;
  }
`;
