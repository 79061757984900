import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import {
  Header,
  Loader,
  MainContainer,
  PageWrapper,
  RootContainer,
} from '@omnipkg/ui-kit-web';

import { useTrackingContext } from '@src/contexts/TrackingContext';

import { useHandleNavigation } from './hooks/useHandleNavigation';
import { useHeaderNavigation } from './hooks/useHeaderNavigation';
import { useZendeskWidget } from './hooks/useZendeskWidget';

export default function MainLayout(): JSX.Element {
  const { trackingStatus } = useTrackingContext();
  const isLoading = trackingStatus === 'pending' || trackingStatus === 'idle';
  const isError = trackingStatus === 'error';

  const { isHideNavigation } = useHeaderNavigation();

  useEffect(() => {
    const fallbackElement = document.getElementById('dp-fallback-loader');
    if (fallbackElement) {
      fallbackElement.style.display = 'none';
    }
  }, []);

  useZendeskWidget();
  useHandleNavigation();

  return (
    <RootContainer>
      {isLoading ? (
        <PageWrapper $verticalAlign="center">
          <Loader size="200" neutral />
        </PageWrapper>
      ) : (
        <>
          {!isError && <Header hideNavigation={isHideNavigation} />}
          <MainContainer>
            <Outlet />
          </MainContainer>
        </>
      )}
    </RootContainer>
  );
}
