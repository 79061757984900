import { Widget } from '@omnic/widget-locations';

import { useWidgetConfig } from '@src/hooks/useWidgetConfig';

export default function Map(): JSX.Element {
  const { config, options } = useWidgetConfig();

  return (
    <div id="@omnic/widget-locations">
      <Widget
        config={{
          ...config,
          disableDelivery: true,
        }}
        options={options}
      />
    </div>
  );
}
