import { ContentWrapper } from '@omnipkg/ui-kit-web';
import styled from 'styled-components';

export const ContentPaper = styled(ContentWrapper)`
  gap: ${({ theme }): string => theme.spacing(2)};
  margin-bottom: ${({ theme }): string => theme.spacing(2)};
`;

export const LoryContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }): string => theme.spacing(2.5)};
  border-radius: ${({ theme }): string => theme.shape.borderRadius.s};
  background-color: #f2eaff;
`;

export const Lory = styled.img`
  display: block;
  position: relative;
  width: 100%;
  max-width: 400px;
  height: auto;
  object-fit: contain;
`;
