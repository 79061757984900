import { useMemo } from 'react';
import { WidgetConfig, WidgetOptions } from '@omnic/widget-locations';
import { useTranslations } from '@omnipkg/ui-kit-web';
import { useTheme } from 'styled-components';

import { useTrackingContext } from '@src/contexts/TrackingContext';
import { TrackingData } from '@src/contexts/TrackingContext/types';

export const WIDGET_BASE_URL = process.env.ECOM_API;
export const INITIAL_ZOOM_MAP = 4;

interface HookReturns {
  config: WidgetConfig;
  options: WidgetOptions;
}

export function useWidgetConfig(): HookReturns {
  const { trackingData: data } = useTrackingContext();
  const { palette } = useTheme();
  const { langData } = useTranslations();

  const trackingData = data as TrackingData;

  const primaryColor = palette.primary;
  const secondaryColor = palette.grey[100];
  const latitude = Number(trackingData.point_latitude_to);
  const longitude = Number(trackingData.point_longitude_to);
  const center = useMemo(() => [latitude, longitude], [latitude, longitude]);

  return {
    config: {
      startScreen: 'map',
      hideLogo: true,
      INN: trackingData.location_widget_inn,
      locale: langData.lang,
      palette: {
        primary: primaryColor,
        'primary-dark': secondaryColor,
        'text-with-theme': '#fff',
      },
      map: {
        initialZoom: INITIAL_ZOOM_MAP,
        provider: 'google',
        center,
      },
      disableGeolocation: true,
      font: 'Roboto',
      mapTheme: 'omnic-light',
      iconType: 'omni-llama',
      logo: 'llama',
    },
    options: {
      baseURL: `${WIDGET_BASE_URL}/type-1/public/`,
    },
  };
}
