import { Widget } from '@omnic/widget-locations';
import {
  PageWrapper,
  useQueryNavigate,
  useTranslations,
} from '@omnipkg/ui-kit-web';

import Footer from '@src/components/Footer';
import InfoMessage from '@src/components/InfoMessage';
import { useTrackingContext } from '@src/contexts/TrackingContext';
import { TrackingData } from '@src/contexts/TrackingContext/types';
import { useWidgetConfig } from '@src/hooks/useWidgetConfig';
import { Routes } from '@src/types/routes';

import { Point } from './type';

import '@omnic/widget-locations/dist/lib/style.css';

import {
  ContentPaper,
  InfoData,
  InfoName,
  InfoWrapper,
  MapContainer,
  OrderInfoContainer,
  Separator,
  Title,
} from './styled';

export default function DeliveryPoint(): JSX.Element {
  const {
    trackingData: data,
    clearPointData,
    recordLocationPoint,
    recordDeliveryAddress,
  } = useTrackingContext();

  const { t } = useTranslations();
  const navigate = useQueryNavigate();

  const trackingData = data as TrackingData;
  const isAddressSelection = trackingData.address_selection;

  const senderName = trackingData.sender_name;
  const destinationTitle = `${t('destinationMessageTitle1')} "${senderName}", ${t('destinationMessageTitle2')}`;

  const { config, options } = useWidgetConfig();

  function onPointClick(point: Point) {
    clearPointData();

    if ('brand' in point) {
      recordLocationPoint(point);
    } else {
      recordDeliveryAddress(point);
    }
    navigate(Routes.deliveryPointConfirmation);
  }

  return (
    <PageWrapper $withoutSidePadding $withoutBottomPadding>
      <ContentPaper>
        <Title>{t('orderDetails')}</Title>
        <OrderInfoContainer>
          <InfoWrapper>
            <InfoName>{t('orderNumber2')}:</InfoName>
            <InfoData>{trackingData.vendor_number}</InfoData>
          </InfoWrapper>
          <Separator />
          <InfoWrapper>
            <InfoName>{t('trackingNumber')}:</InfoName>
            <InfoData>{trackingData.order_number}</InfoData>
          </InfoWrapper>
        </OrderInfoContainer>
        <InfoMessage
          title={destinationTitle}
          text={t('destinationMessageText')}
        />
      </ContentPaper>
      <MapContainer id="@omnic/widget-locations">
        <Widget
          config={{
            ...config,
            disableDelivery: !isAddressSelection,
            onPointClick,
          }}
          options={options}
        />
      </MapContainer>
      <Footer />
    </PageWrapper>
  );
}
