import { SVGIcon } from '@omnipkg/ui-kit-web';

import { InfoMessageProps } from './types';

import { Container, MessageText, MessageTitle, MessageWrapper } from './styled';

export default function InfoMessage({ title, text }: InfoMessageProps) {
  return (
    <Container>
      <SVGIcon name="location-point-2" fillVariant="primary" />
      <MessageWrapper>
        <MessageTitle>{title}</MessageTitle>
        <MessageText>{text}</MessageText>
      </MessageWrapper>
    </Container>
  );
}
